import React from 'react'
import Img from 'gatsby-image'
import PageTitle from 'components/page-title'

import './page-header.scss'

const PageHeader = ({ title, subtitle, sizes }) => (
  <div className="page-header">
    <PageTitle title={title} />
    <Img sizes={sizes} />
    <div className="content">
      <h1>{title}</h1>
      <p>{subtitle}</p>
    </div>
  </div>
)

export default PageHeader
