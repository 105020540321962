import React from 'react'
import Helmet from 'react-helmet'

const PageTitle = ({ title }) => (
  <Helmet>
    <title>{title} &ndash; Howell Farms</title>
  </Helmet>
)

export default PageTitle
