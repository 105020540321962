import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from 'components/layout'
import PageHeader from 'components/page-header'

const InnovationPage = ({ data }) => (
  <Layout>
    <PageHeader 
      title="Innovation" 
      sizes={data.headerImage.childImageSharp.fluid} 
    />

    <div className="l-primary">
      <nav>
        <h3>Fundamentals</h3>
        <ul>
          <li><Link to={'/fundamentals/family-farming'}>Family Farming</Link></li>
          <li><Link to={'/fundamentals/farm-team'}>Farm Team</Link></li>
          <li><Link to={'/fundamentals/farm-history'}>Farm History</Link></li>
          <li>
              <Link to={'/fundamentals/what-we-grow'}>What We Grow</Link>
              <ul>
                <li><Link to={'/fundamentals/what-we-grow/tomatoes'}>Tomatoes</Link></li>
                <li><Link to={'/fundamentals/what-we-grow/grains'}>Grains</Link></li>
              </ul>   
          </li>
          <li><Link to={'/fundamentals/sustainability'}>Sustainability</Link></li>
        </ul>  
      </nav>
      <section>
        <span>Page coming soon&hellip;</span>
      </section>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "innovation-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default InnovationPage
